import { BrowserModule } from "@angular/platform-browser";
import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"; 
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";   
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material";
import { MaterialModule } from "../app/material-module";
import { HttpClientModule } from "@angular/common/http";
import { CarouselModule,OwlOptions } from "ngx-owl-carousel-o";


import { ComponentsModule } from "./components/components.module";

@NgModule({
  declarations: [AppComponent ],
  
  imports: [
    BrowserModule,
    MaterialModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    CarouselModule,
    FontAwesomeModule,
    PickerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    
    
  ],
 
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
