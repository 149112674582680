import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rate-with-text',
  templateUrl: './rate-with-text.component.html',
  styleUrls: ['./rate-with-text.component.css']
})
export class RateWithTextComponent implements OnInit {

  buttons = [
    { title: 'Excellent', isClickable: false , disable: false},
    { title: 'Good', isClickable: false, disable: false},
    { title: 'Average', isClickable: false, disable: false},
    { title: 'Poor', isClickable: false, disable: false},
  ];
  constructor() { }

  ngOnInit() {
  }
  setEduction(index) {
    this.buttons[index].isClickable = true;
    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].disable = true;
    }
  }

}
