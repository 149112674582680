import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {ScrollService} from '../../../services/scroll.service';

@Component({
  selector: 'app-bottom-button-sliders',
  templateUrl: './bottom-button-sliders.component.html',
  styleUrls: ['./bottom-button-sliders.component.css']
})
export class BottomButtonSlidersComponent implements OnInit {

  constructor(private scrollService: ScrollService) { }
  @Input() buttons;
  @Output() sendMessageEV= new EventEmitter<any>();

  public currentBtn = 1;
  

  ngOnInit() {
    if(this.buttons.resButton.length <= 2){
      document.getElementById('left').style.display = "none";
      document.getElementById('right').style.display = "none";
    }
  }
  scroll(target: HTMLElement, direction) {
    // const increment = target.scrollLeft + target.offsetWidth / 2;
    if (direction === 'right') {
      if (this.currentBtn !== this.buttons.resButton.length) {
        this.currentBtn += 1;
      }
    } else {
      if (this.currentBtn !== 1) {
        this.currentBtn -= 1;
      }
    }
    this.scrollService.scroll(target, direction, 180);
  }
  sendMessage(message, divId, divId1){
    document.getElementById(divId).style.background = "#182F7C";
    // var id:any = parseInt(event.target.id) + 100
    // id = id.toString()
    document.getElementById(divId1).style.color = "white";
    this.sendMessageEV.emit(message);
  }

}
