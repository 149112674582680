import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { webSocket } from "rxjs/webSocket";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { environment } from "src/environments/environment";

export class Message {
  constructor(
    public content: string,
    public sentBy: string,
    public timestamp: number,
    public resCard: any,
    public slotToElicit: string,
    public imageData: string,
    public showForm: boolean,
    public table?: Object,
    public imageDescription?: Object,
    public showImage?: boolean
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class ChatService {
  private typingIndicator = new Subject<any>();
  public msg: Message;
  public multilineMsg: string = "";
  public subject;
  public reqTime;
  userDetails = {};
  arabic = /[\u0600-\u06FF\u0750-\u077F]/;
  conversation = new BehaviorSubject<Message[]>([]);     
  public apiUrl =
    "https://5iyx9toqx2.execute-api.us-west-2.amazonaws.com/prod/chatbot/web-view"; 
  
    chatbots = [{
      "Id" : "3e39e630-e4a6-11ee-94de-795c50d2dffd",
      "language" : "en"
  },    
  {
      "Id" : "55a82ba0-e4a7-11ee-b13f-67393463d1b6",
      "language" : "ar"
  }]
 stopAgent=false;
 agentSwitch = false;
  public chatbotId: string = "3e39e630-e4a6-11ee-94de-795c50d2dffd"; 

  constructor(private http: HttpClient) {}

  update(msg: Message) {
    this.conversation.next([msg]);
  }

  typingIndicatorFn(): Observable<any> {
    return this.typingIndicator.asObservable();
  }

  callwebApi(body: any): Observable<any> {
    const apiUrl = this.apiUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    console.log("data from api",this.http.post<any>(apiUrl, body, httpOptions))
    return this.http.post<any>(apiUrl, body, httpOptions);
  }
  getSignedUrlToUpload(body: any): Observable<any> {
    const apiUrl = `${environment.ceServerURL}live-agent/signed-attachment`;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post<any>(apiUrl, body, httpOptions);
  }
  uploadFile(url: string, file: File): Observable<any> {
    const headers = new HttpHeaders();
    console.log(file);
    headers.set("Content-Type", `${file.type}`);
    const params = new HttpParams();
    return this.http.put(url, file, { params, headers });
  }
  callsavefile(body: any): Observable<any> {
    const apiUrl =
      "https://obwruxueyb.execute-api.eu-west-1.amazonaws.com/Foundry-chatbot/save";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json", 
      }),
    };
    return this.http.post<any>(apiUrl, body, httpOptions);
  }

  converse(
    msg: any,
    sessionId:any,
    sessionState:any,
    userInfo: any,
    sessionDetails: any,
    seesionAtribb: any,
    slotToElicit: any,
    image: any,
    showMsg: any
  ): Promise<any> {
    this.typingIndicator.next(true);
    let sendReply = true;
    return new Promise((resolve, reject) => {
      console.log('showMsg-->',showMsg);
      if (showMsg) {
        console.log("msg 112",msg)
        let pdf=showMsg;
        console.log("pdf 112",pdf)
        console.log("image 112",image)
        let userMessage3 = new Message(
          pdf,
          "bot",
          new Date().getTime(),
          null,
          null,
          image,
          false,
          null,
          null,
          false
        );
        this.update(userMessage3);

      } else {
        console.log("msg 111",msg)
        const userMsg = new Message(
          msg,
          "bot",
          new Date().getTime(),
          null,
          null,
          image,
          false
        );
        this.update(userMsg);
      }


      let a = {
        message: msg.trim(),
        botId: "FC9MPXYRK8",
        botAliasId: "TSTALIASID", 
        
        chatbotId: "3e39e630-e4a6-11ee-94de-795c50d2dffd",  
        sessionId: sessionId,  
        chatbots: [{
          "Id" : "3e39e630-e4a6-11ee-94de-795c50d2dffd",
          "language" : "en"
          },
          {
              "Id" : "55a82ba0-e4a7-11ee-b13f-67393463d1b6",
              "language" : "ar"
          }]
      };
      
      if(sessionState){
        a["sessionState"] = sessionState
      }
      if (image) {
        console.log("line 164",sessionState)  
        
        a["messageFormate"] = "file";
        a["sessionAttributes"] = {
          inputText: "Not supportedd",
          inputData: image,
        };
      } else {
        a["messageFormate"] = "plainText";
        a["sessionAttributes"] = {
          inputText: msg.trim(),
        };
      }
      if (userInfo) {
        a["userInfo"] = userInfo;
        this.userDetails = userInfo;
      }
      if (sessionDetails) {
        if (Object.keys(sessionDetails).length > 0) {
          a["sessionDetails"] = sessionDetails;
        }
      }
      this.multilineMsg = this.multilineMsg + "" + msg;
      a["message"] = this.multilineMsg;
      if (sessionDetails) {
        if (
          sessionDetails["intentName"] == "imageOCR" &&
          sessionDetails.slotToElicit == "imageload"
        ) {
          if (image) {
            sessionDetails.sessionAttributes.imageData = image;
            a["message"] = "abide";
          } else {
          }
        }
         if (image) {
          sendReply = false;
          let a = {}
          if (sessionDetails) {     
            if (Object.keys(sessionDetails).length > 0) {
              a["sessionDetails"] = sessionDetails;
            }
          }
          if (sessionDetails) {
            if (Object.keys(sessionDetails).length > 0) {
              a["sessionDetails"] = sessionDetails;
              
            }
          }
          const body = { 
            base64_data: image,
            filename: 
              "profile." +
              image.substring(image.indexOf("/") + 1, image.indexOf(";")),a
          };
          this.callsavefile(body).subscribe((data) => {
            console.log('data', data);
          

            if (sessionDetails.language=="en"){
            const userMessage = new Message(
              data.message, 
              "user",
              data.timestamp,
              data.responseCard ? data.responseCard : null,
              data.slotToElicit ? a["sessionDetails"].slotToElicit : null, 
              null,  
              true,  
             false
            );    
            this.update(userMessage);}
            else {
              console.log("line 241",data.message)
              const userMessage = new Message( 
                data.message,
                "user",
                data.timestamp,
                data.responseCard ? data.responseCard : null,
                data.slotToElicit ? a["sessionDetails"].slotToElicit : null,
                null,
              true,
                false
              );
              this.update(userMessage);}
              resolve(data);    

            // this.lastMsg = {
            //   timestamp: 1589366716,
            //   message: 'Document Uploaded',
            //   showForm: true   
            // };
          });
        }
      
        if (sessionDetails["intentName"] == "updateId" && image) {
          sendReply = false;
          // console.log('-->',image.substring(image.indexOf('/')+1,image.indexOf(';')));
          const body = {
            base64_data: image,
            filename:
              "profile." +
              image.substring(image.indexOf("/") + 1, image.indexOf(";")),
          };
          this.callsavefile(body).subscribe((data) => {
            // console.log('data', data);
            const userMessage = new Message(
              "Document has been uploaded successfully.",
              "user",
              new Date().getTime(),
              null,
              null,
              null,
              false
            );
            this.update(userMessage);

            // this.lastMsg = {
            //   timestamp: 1589366716,
            //   message: 'Document Uploaded',
            //   showForm: true
            // };
          });
        }
      }
      if(seesionAtribb){
        a["sessionState"]["sessionAttributes"] = seesionAtribb
      }
      
      // console.log('What is my message ',a['message']);
      if (sendReply) {

        this.callwebApi(a).subscribe(
          (data) => {
            console.log("response 218",data)
            if(data.message){ 
              const removableIndexes = [];
              console.log("data.message",data.message)
              let messages = data.message;
              // messages.forEach((msg, index) => {
              //   console.log("dataaaa",msg);
              //   if (msg.indexOf('<button>') !== -1) {
              //     msg = msg.replace('<button>', '');
              //     const content = JSON.parse(msg);
              //     messages.splice(index, 1);
              //     messages = messages.concat(content);
              //   }
              // });
              // const content = JSON.parse(messages);
                  //  messages.splice(index, 1);
                   messages = messages.concat(data.message);
                  //  data.message = messages;
                    this.typingIndicator.next(false);
              // if (!data["sessionDetails"] || true) {
                      //(data['chatbotId'] == 'lexKendraIntegration' && true)
                    //  if(data["sessionDetails"]){
                    //    data.message= data.messages
                    //  }
                      this.multilineMsg = "";
        
                      // console.log('show form->',data);
        
                      if (msg == "test") {
                        // console.log('Test is called');
                      }
                      if (data.chatUrl ) {
                        // console.log('url ',data.chatUrl);
                        this.createWebSocketConn(data);
                      }
                      if (data.closeConnection) {
                        this.disconnectConn();
                      }
        
                      let t = 0;
                      console.log(data);
                      // let values = data.message.split(" ");
                      // values.forEach((ele, index) => {
                      //   if (ele.includes("http")) {
                      //     if (sessionDetails.language == "en") {
                      //       values[index] =
                      //         '<a href="' +
                      //         ele +
                      //         '" target="_blank"> click here ' +
                      //         "</a>";
                      //     } else {
                      //       values[index] =
                      //         '<a href="' +
                      //         ele +
                      //         '" target="_blank"> اضغط هنا  ' +
                      //         "</a>";
                      //     }
                      //   }
                      // });
                      // values = values.join(" ");
                      // data.message = values; 
                      if(data.hasOwnProperty("messages")){ 
                        let t = 0;
                        console.log("data >>", data);
                        var speech ="";
                        // if (data.messages[0].contentType == "PlainText") {
                        //     data.message = data.messages[0].content.replace(/\*/g, function (match) {
                        //     t++;
                        //     return t % 2 == 0
                        //       ? "</span>"
                        //       : "<span class='boldMsg' style='font-weight: bold important!;'>";
                        //   });
                        //   data.message = data.messages[0].content.replace("Hello", "<br/> Hello");
                        //   speech = data.messages[0].content;
                        // }
                        console.log("hhhh 291",data.messages)
                        data.messages.forEach(message => {
                          if (message.contentType == "PlainText"){
                              // data.message = message.content.replace(/\*/g, function (match) {
                              //   t++;
                              //   return t % 2 == 0
                              //     ? "</span>"
                              //     : "<span class='boldMsg' style='font-weight: bold important!;'>";
                              // });
                              // data.message = message.content.replace("Hello", "<br/> Hello");
                              
                            
                              
                            let item = message.content.replace(/\\n/gi, "<br />");
                            console.log("item", item)
                            // let checkItem = items;
                            // checkItem.forEach((item,index)=>{
                            //   if(item.length <= 2){
                            //     items.splice(index,1);
                            //   }
                            // });
                            // items.forEach((item, index) => {
                              // if(item.length > 0){
                              // console.log('index-->',item.length,index);
                              if (data.sessionDetails) {
                                data.slotToElicit = data.sessionDetails.slotToElicit;
                              }
                              const userMessage = new Message(
                                item,
                                "user",
                                new Date().getTime(),
                                data.responseCard ? data.responseCard : null,
                                data.slotToElicit ? data.slotToElicit : null,
                                null,
                                false
                              ); 
                              this.update(userMessage);
                              
        
                              // }
                            // });
                       
                           } 
                           else if ( message.contentType == "ImageResponseCard" 
                      || (message.contentType == "CustomPayload" && message.content.includes("<button>")) ) {
                    var content = ""
                    console.log("here 511",message)
                    if ( (message.contentType == "CustomPayload" || message.contentType == "ImageResponseCard")  ){
                      message["imageResponseCard"] = message.content.replace(/<button>/gi, "")
                      message["imageResponseCard"] = JSON.parse(message["imageResponseCard"].trim())
                      message["imageResponseCard"] = JSON.parse(message["imageResponseCard"].trim())
                      message.contentType = "ImageResponseCard"
                      console.log("message 517",message)
                      delete message.content;

                    }
                    // if(message.imageResponseCard.title.includes("Is there anything else I can help you with?")){
                    //   content = "NBI"
                    //   const NBIMessage = new Message(
                    //     message.imageResponseCard.title,
                    //     "user",
                    //     new Date().getTime(),
                    //     null,
                    //     null,
                    //     null,
                    //     false
                    //   );
                    //   this.update(NBIMessage);
                    // } 
                    if(message.imageResponseCard.hasOwnProperty("title")){
                      message.imageResponseCard.title = message.imageResponseCard.title.replace(/\\n/gi, "<br />");
                    }
                    if(message.imageResponseCard.hasOwnProperty("subtitle")){
                      message.imageResponseCard.subtitle = message.imageResponseCard.subtitle.replace(/\\n/gi, "<br />");
                    }
                    const userMessage = new Message(
                      content,
                      "user",
                      new Date().getTime(),
                      message.contentType == "ImageResponseCard" ? {"genericAttachments" :[ message.imageResponseCard]} : null,
                      data.slotToElicit ? data.slotToElicit : null,
                      null,
                      false
                    );
                    console.log("message after update",userMessage)
                    this.update(userMessage);
                      }
                          //else if ( message.contentType == "ImageResponseCard" 
                          //     || (message.contentType == "CustomPayload" && message.content.includes("<button>")) ) {
                          //       console.log("test 335",message.contentType )
                                
                          //   var content = ""
                          //   if ( message.contentType == "CustomPayload" && message.content.includes("<button>") ){
                          //     message["imageResponseCard"] = message.content.replace(/<button>/gi, "")
                          //     message["imageResponseCard"] = JSON.parse(message["imageResponseCard"].trim())
                          //     message.contentType = "ImageResponseCard"
                          //     delete message.content;
                          //   }
                          //   if(message.imageResponseCard.title.includes("Is there anything else I can help you with?")){
                          //     content = "NBI"
                          //     const NBIMessage = new Message(
                          //       message.imageResponseCard.title,
                          //       "user",
                          //       new Date().getTime(),
                          //       null,
                          //       null,
                          //       null,
                          //       false
                          //     );
                          //     this.update(NBIMessage);
                          //   } 
                          //   if(message.imageResponseCard.hasOwnProperty("title")){
                          //     message.imageResponseCard.title = message.imageResponseCard.title.replace(/\\n/gi, "<br />");
                          //   }
                          //   if(message.imageResponseCard.hasOwnProperty("subtitle")){
                          //     message.imageResponseCard.subtitle = message.imageResponseCard.subtitle.replace(/\\n/gi, "<br />");
                          //   }
                          //   const userMessage = new Message(
                          //     content,
                          //     "user",
                          //     new Date().getTime(),
                          //     message.contentType == "ImageResponseCard" ? {"genericAttachments" :[ message.imageResponseCard]} : null,
                          //     data.slotToElicit ? data.slotToElicit : null,
                          //     null,
                          //     false
                          //   );
                          //   this.update(userMessage);
                          // }
                          
                          else if( message.contentType == "CustomPayload" ){
                            var content = ""
                            if( message.content.includes("<table>") ){
                              message.content = message.content.replace(/<table>/gi, "")
                              content = "table"
                              var table = JSON.parse(message.content)
                            } else if( message.content.includes("<image>") ){
                              message.content = message.content.replace(/<image>/gi, "")
                              content = "image"
                            }
                            const userMessage = new Message(
                              content,
                              "user",
                              new Date().getTime(),
                              null,
                              null,
                              null,
                              false,
                              content == "table" ? JSON.parse(message.content) : undefined,
                              content == "image" ? JSON.parse(message.content) : undefined
                            );
                            this.update(userMessage);
                            
                          }
                          else if( message.contentType == "CustomPayload" ){
                            var content = ""
                            if( message.content.includes("<table>") ){
                              message.content = message.content.replace(/<table>/gi, "")
                              content = "table"
                              var table = JSON.parse(message.content)
                            } else if( message.content.includes("<image>") ){
                              message.content = message.content.replace(/<image>/gi, "")
                              content = "image"
                            }
                            const userMessage = new Message(
                              content,
                              "user",
                              new Date().getTime(),
                              null,
                              null,
                              null,
                              false,
                              content == "table" ? JSON.parse(message.content) : undefined,
                              content == "image" ? JSON.parse(message.content) : undefined
                            );
                            this.update(userMessage);
                            
                          }
                        });
                      } else if (data.hasOwnProperty('message')) {
                        console.log("line 556",data.message)
                        const userMessage = new Message(
                          data.message,
                          "user",
                          new Date().getTime(),
                          data.responseCard ? data.responseCard : null,
                          data.slotToElicit ? data.slotToElicit : null,
                          null,
                          false
                        );
                        this.update(userMessage);
                      } else {
                        const userMessage = new Message(
                          "can you please repeat that?",
                          "user",
                          new Date().getTime(),
                          data.responseCard ? data.responseCard : null,
                          data.slotToElicit ? data.slotToElicit : null,
                          null,
                          false
                        );
                        this.update(userMessage);
                      }
                      resolve(data);
        
                      if (data["sessionDetails"]) {
                        if (
                          data["sessionDetails"].intentName == "supportTicket" &&
                          data["sessionDetails"].slotToElicit == "ticket"
                        ) {
                          const userMessage = new Message(
                            "form",
                            "form",
                            new Date().getTime(),
                            data.responseCard ? data.responseCard : null,
                            data.slotToElicit ? data.slotToElicit : null,
                            null,
                            true
                          );
                          this.update(userMessage);
                        }
                      }
                    }

            // }
            else{
      console.log("hhhh",data)
      const removableIndexes = [];
      let messages = null
      if(data.messages){
       messages = [...data.messages];
       data.messages = [...messages];}
      console.log("messages 602",messages)
     // data.messages.forEach((msg, index) => {
       // if (msg.content.indexOf('<button>') !== -1) {
        //  msg.content = msg.content.replace('<button>', '');
         // const content = JSON.parse(msg.content);
         // messages.splice(index, 1);
         // messages = messages.concat(content);
       // }
     // });
      
            this.typingIndicator.next(false);
      if (!data["sessionDetails"] || true) {
              this.multilineMsg = "";   
              if (data.messages[0].content.includes("please standby while i am connecting with one of our live Agent;")) {                
                data= JSON.parse(data.messages[0].content.replace("please standby while i am connecting with one of our live Agent;",""))
                console.log("line 602",data)            
                this.createWebSocketConn(data);
              }
              if (data.closeConnection) {        
                this.disconnectConn();
              }

              let t = 0;
              console.log(data);
              
              if(data.hasOwnProperty("messages")){ 
                let t = 0;
                console.log("data >>", data);
                var speech ="";
                // if (data.messages[0].contentType == "PlainText") {
                //     data.message = data.messages[0].content.replace(/\*/g, function (match) {
                //     t++;
                //     return t % 2 == 0
                //       ? "</span>"
                //       : "<span class='boldMsg' style='font-weight: bold important!;'>";
                //   });
                //   data.message = data.messages[0].content.replace("Hello", "<br/> Hello");
                //   speech = data.messages[0].content;
                // }
                data.messages.forEach(message => {
                  console.log("469")
                  if (message.contentType == "PlainText"){
                      // data.message = message.content.replace(/\*/g, function (match) {
                      //   t++;
                      //   return t % 2 == 0
                      //     ? "</span>"
                      //     : "<span class='boldMsg' style='font-weight: bold important!;'>";
                      // });
                      // data.message = message.content.replace("Hello", "<br/> Hello");
                      
                    
                      
                    let item = message.content.replace(/\\n/gi, "<br />");
                    console.log("item", item)
                    // let checkItem = items;
                    // checkItem.forEach((item,index)=>{
                    //   if(item.length <= 2){
                    //     items.splice(index,1);
                    //   }
                    // });
                    // items.forEach((item, index) => {
                      // if(item.length > 0){
                      // console.log('index-->',item.length,index);
                      if (data.sessionDetails) {
                        data.slotToElicit = data.sessionDetails.slotToElicit;
                      }
                      console.log("line 680",item)
                      const userMessage = new Message(
                        item,
                        "user",
                        new Date().getTime(),
                        data.responseCard ? data.responseCard : null,
                        data.slotToElicit ? data.slotToElicit : null,
                        null,
                        false
                      ); 
                      this.update(userMessage);
                      

                      // }
                    // });
                  } else if ( (message.contentType == "CustomPayload" && message.content.includes("<button>")) ) {
                    var content = ""
                    console.log("here 511",message)
                    if ( (message.contentType == "CustomPayload" || message.contentType == "ImageResponseCard")  ){
                      console.log("here 511",message.imageResponseCard)  
                      message["imageResponseCard"] = message.content.replace(/<button>/gi, "")
                      message["imageResponseCard"] = JSON.parse(message["imageResponseCard"])
                      message["imageResponseCard"] = JSON.parse(message["imageResponseCard"])
                      message.contentType = "ImageResponseCard"
                      console.log("message 517",message)
                      delete message.content;

                    }
                    // if(message.imageResponseCard.title.includes("Is there anything else I can help you with?")){
                    //   content = "NBI"
                    //   const NBIMessage = new Message(
                    //     message.imageResponseCard.title,
                    //     "user",
                    //     new Date().getTime(),
                    //     null,
                    //     null,
                    //     null,
                    //     false
                    //   );
                    //   this.update(NBIMessage);
                    // } 
                    if(message.imageResponseCard.hasOwnProperty("title")){
                      message.imageResponseCard.title = message.imageResponseCard.title.replace(/\\n/gi, "<br />");
                    }
                    if(message.imageResponseCard.hasOwnProperty("subtitle")){
                      message.imageResponseCard.subtitle = message.imageResponseCard.subtitle.replace(/\\n/gi, "<br />");
                    }
                    console.log("line 727",content)
                    const userMessage = new Message(
                      content,
                      "user",
                      new Date().getTime(),
                      message.contentType == "ImageResponseCard" ? {"genericAttachments" :[ message.imageResponseCard]} : null,
                      data.slotToElicit ? data.slotToElicit : null,
                      null,
                      false
                    );
                    console.log("message after update",userMessage)
                    this.update(userMessage);
                  } else if ( message.contentType == "ImageResponseCard" ) {       
                    if(message.imageResponseCard.hasOwnProperty("title")){
                      message.imageResponseCard.title = message.imageResponseCard.title.replace(/\\n/gi, "<br />");
                    }
                    if(message.imageResponseCard.hasOwnProperty("subtitle")){
                      message.imageResponseCard.subtitle = message.imageResponseCard.subtitle.replace(/\\n/gi, "<br />");
                    }
                    const userMessage = new Message(
                      "",
                      "user",
                      new Date().getTime(),
                      message.contentType == "ImageResponseCard" ? {"genericAttachments" :[ message.imageResponseCard]} : null,
                      data.slotToElicit ? data.slotToElicit : null,
                      null,
                      false
                    );
                    this.update(userMessage);
                  } 
                   else if( message.contentType == "CustomPayload" ){
                    var content = ""
                    if( message.content.includes("<table>") ){
                      message.content = message.content.replace(/<table>/gi, "")
                      content = "table"
                      var table = JSON.parse(message.content)
                    } else if( message.content.includes("<image>") ){
                      message.content = message.content.replace(/<image>/gi, "")
                      content = "image"
                    }
                    const userMessage = new Message(
                      content,
                      "user",
                      new Date().getTime(),
                      null,
                      null,
                      null,
                      false,
                      content == "table" ? JSON.parse(message.content) : undefined,
                      content == "image" ? JSON.parse(message.content) : undefined
                    );
                    this.update(userMessage);
                    
                  }
                });
              } else if (data.hasOwnProperty('message')) {
                console.log("line 783",data.message)
                const userMessage = new Message(
                  data.message,
                  "user",
                  new Date().getTime(),
                  data.responseCard ? data.responseCard : null,
                  data.slotToElicit ? data.slotToElicit : null,
                  null,
                  false
                );
                this.update(userMessage);
              } else {
                const userMessage = new Message(
                  "can you please repeat that?",
                  "user",
                  new Date().getTime(),
                  data.responseCard ? data.responseCard : null,
                  data.slotToElicit ? data.slotToElicit : null,
                  null,
                  false
                );
                this.update(userMessage);
              }
              resolve(data);

              if (data["sessionDetails"]) {
                if (
                  data["sessionDetails"].intentName == "supportTicket" &&
                  data["sessionDetails"].slotToElicit == "ticket"
                ) {
                  const userMessage = new Message(
                    "form",
                    "form",
                    new Date().getTime(),
                    data.responseCard ? data.responseCard : null,
                    data.slotToElicit ? data.slotToElicit : null,
                    null,
                    true
                  );
                  this.update(userMessage);
                }
              }
            }
          }
          },
          (err) => {
            console.log("err", err);
          }
        );
      }
    });
  }

  createWebSocketConn(data: any) {
    localStorage.setItem('agentSwitch', 'true');
    this.agentSwitch= true;
    this.subject = webSocket(data.chatUrl);
    this.subject.subscribe(
      (msgRes) => {
        //console.log('message received: ' + JSON.stringify(msgRes));
        console.log("this.stopAgent ->",this.stopAgent)
        console.log(this.stopAgent)
        if (typeof msgRes.content == "string"  ) {
          console.log("user data -> ",data);
          const messageContent = JSON.parse(msgRes.content);
          console.log("test 834",messageContent)
          console.log("test 834",messageContent.Content)
          if  ( messageContent.ContentType ==
          "application/vnd.amazonaws.connect.event.participant.left"
        ){
          this.agentSwitch=false;
          localStorage.setItem('agentSwitch', 'false');
        }
        console.log("messageContent -> ",messageContent);
        console.log("messageContent.Content -> ",messageContent.Content);
        if ((messageContent.ContentType == "application/vnd.amazonaws.connect.event.participant.left" && messageContent.ParticipantRole == "AGENT") ||
        (messageContent.Content && messageContent.Content.includes("The agent has disconnected.") )
      ) {
          
          console.log("conversation -> ",this.conversation)
          let la = "";
          if (data.language == "ar_AE") {
            la = "غادر مستشار خدمة العملاء المحادثة";
          } else {
            la = "Connection lost with live agent, please try again later";
          }
          const userMessage1 = new Message(
            la,
            "user",
            new Date().getTime(),
            data.responseCard ? data.responseCard : null,
            data.slotToElicit ? data.slotToElicit : null,
            null,
            false
          ); 
          this.update(userMessage1);
          this.disconnectConn();
          this.closeAgentConnection(data).subscribe((data) => {
            const userMessage1 = new Message(
              data.message,
              "user",
              new Date().getTime(),
              data.responseCard ? data.responseCard : null,
              data.slotToElicit ? data.slotToElicit : null,
              null,
              false
            );
            this.update(userMessage1);
          });
          this.agentSwitch=false;
          localStorage.setItem('agentSwitch', 'false');
        } else 
          if (     
            messageContent.ContentType ==
            "application/vnd.amazonaws.connect.event.participant.left"
            && !this.stopAgent
          ) {
         
            console.log("conversation -> ",this.conversation)
            let la = "";
            if (data.language == "ar_AE") {
              la = "غادر مستشار خدمة العملاء المحادثة";
            } else {
              la = "Agent left the conversation.";
            }
            const userMessage1 = new Message(
              la,
              "user",
              new Date().getTime(),
              data.responseCard ? data.responseCard : null,
              data.slotToElicit ? data.slotToElicit : null,
              null,
              false
            ); 
            this.update(userMessage1);
            this.disconnectConn();
            this.closeAgentConnection(data).subscribe((data) => {
              const userMessage1 = new Message(
                data.message,
                "user",
                new Date().getTime(),
                data.responseCard ? data.responseCard : null,
                data.slotToElicit ? data.slotToElicit : null,
                null,
                false
              );
              this.update(userMessage1);
            });
            this.agentSwitch=false;
            localStorage.setItem('agentSwitch', 'false');
          } else{
            setTimeout(() => {
              this.stopAgent=false;
            }, 60000);
                    }
          if (
            messageContent.ContentType ==
              "application/vnd.amazonaws.connect.event.participant.joined" &&
            messageContent.ParticipantRole == "AGENT"
          ) {
            let la = "";
            if (data.language == "ar_AE") {
            
              la = "انضم مستشار خدمة العملاء إلى المحادثة";
            } else {
              la = "Agent joined the conversation.";
            }
            const userMessage1 = new Message(
              la,
              "user",
              new Date().getTime(),
              data.responseCard ? data.responseCard : null,
              data.slotToElicit ? data.slotToElicit : null,
              null,
              false
            );
            this.update(userMessage1);
          }     
          if ( messageContent.Content == "Something went wrong. Goodbye."  &&
          messageContent.ParticipantRole == "AGENT") {
            console.log("test 891", data)
            let la = "";
            let responseCard = {};
            if (data.language == "ar_AE") {    
              la =
                "'الموظفين المسؤولين عن خدمة العملاء غير متواجدين في الوقت الحالي، يسرنا مساعدتك في خدمة المحادثة المباشرة خلال أيام العمل من الساعة التاسعة صباحاً وحتى الخامسة مساءاُ\nهل نعاود الاتصال بك لاحقاً؟";
              responseCard = {
                genericAttachments: [
                  {
                    buttons: [
                      {
                        text: "نعم",

                        value: "نعم"
                      },

                      {
                        text: "لا",

                        value: "لا",
                      },
                    ],

                    

                    title: "",
                  },
                ],
              };
            } else {
              la =
                "Our live agents are not available now, we are happy to serve you in online conversation service during the business days from 9 am to 5 pm.\nWould you like us to call you back?";
              responseCard = {
                "genericAttachments": [             
                  {
                    "title": "",
                    "buttons": [
                      {
                        "text": "Yes",
                        "value": "Yes"
                      },

                      {
                        "text": "No",
                        "value": "No"
                      },
                    ],

                    

                   
                  },
                ],
              };
            }
 
            this.disconnectConn();
            this.closeAgentConnection(data).subscribe((data) => {
              const userMessage1 = new Message(
                la,
                "user",
                new Date().getTime(),
                responseCard,
                data.slotToElicit ? data.slotToElicit : null,
                null,
                false
                
              );
              this.update(userMessage1);
            });
          }
         if (messageContent.ContentType ==
            "application/vnd.amazonaws.connect.event.chat.ended") {          
              console.log("test 891", data)
              let la = "";
              let responseCard = {};
              if(!this.stopAgent){
                if (data.language == "ar_AE") {    
                  la =
                    "'الموظفين المسؤولين عن خدمة العملاء غير متواجدين في الوقت الحالي، يسرنا مساعدتك في خدمة المحادثة المباشرة خلال أيام العمل من الساعة التاسعة صباحاً وحتى الخامسة مساءاُ\nهل نعاود الاتصال بك لاحقاً؟";
                  responseCard = {
                    genericAttachments: [
                      {
                        buttons: [
                          {
                            text: "نعم",
    
                            value: "نعم",
                          },
    
                          {
                            text: "لا",
    
                            value: "لا",
                          },
                        ],
    
                        
    
                        title: "",
                      },
                    ],
                  };
                } else {
                  la =
                    "Our live agents are not available now, we are happy to serve you in online conversation service during the business days from 9 am to 5 pm.\nWould you like us to call you back?";
                  responseCard = {
                    "genericAttachments": [             
                      {
                        "title": "",
                        "buttons": [
                          {
                            "text": "Yes",
                            "value": "Yes"
                          },
    
                          {
                            "text": "No",
                            "value": "No"
                          },
                        ],
    
                        
    
                       
                      },
                    ],
                  };
                }
              }
          
              this.disconnectConn();
              this.closeAgentConnection(data).subscribe((data) => {
                const userMessage1 = new Message(
                  la,
                  "user",
                  new Date().getTime(),
                  responseCard,
                  data.slotToElicit ? data.slotToElicit : null,
                  null,
                  false
                  
                );
                console.log("userMessage1 ",userMessage1);
                this.update(userMessage1);
              });
            }
          if (messageContent.Content == "testing 1") {
            let la = "";
            if (data.language == "ar_AE") {
              la =
                "جميع الموظفين المسؤولين عن خدمة العملاء مشغولين حاليا مع الزبائن الآخرين. يرضى الانتظار قليلاً ريثما ينضم إليك أحد موظفي الخدمة. شكراً لتفهمك";
            } else {
              la =
                "Our live agents are currently busy with other customers. Please wait a few moments an agent will be with you shortly, thanks for your understanding.";
            }
            const userMessage1 = new Message(
              la,
              "user",
              new Date().getTime(),
               null,
              null,
              null,
              false,
              true
            );
            console.log("userMessage2 ",userMessage1);
            this.update(userMessage1);
          }
          if (
            messageContent.ParticipantRole == "AGENT" &&
            messageContent.Content
          ) {
            let speech1 = messageContent.Content.toString();
            console.log("speech1",speech1)
            if (speech1.indexOf("attachment:") !== -1) {
              const fileName = speech1.split(":")[1];
              const fileType = speech1.split(":")[2];
              const obj = {
                fileName: fileName.split("/")[1],
                fileType,
                mode: "Get",
              };
              this.getSignedUrlToUpload(obj).subscribe((res) => {
                if (res && res.url) {
                  let imageData = null;
                  if (fileType.indexOf("image/") !== -1) {
                    imageData = res.url;
                  } else {
                    speech1 = this.replaceLinksInMessage(res.url, obj.fileName);
                  }
                  console.log("speech1 -> ",speech1)
                  const userMessage1 = new Message(
                    speech1,
                    "bot",
                    new Date().getTime(),
                    data.responseCard ? data.responseCard : null,
                    data.slotToElicit ? data.slotToElicit : null,
                    imageData,
                    false,
                    null,
                    null,
                    false
                  );
                  this.update(userMessage1);
                }
              });
            } else {
              const userMessage1 = new Message(
                speech1,
                "user",
                new Date().getTime(),
                data.responseCard ? data.responseCard : null,
                data.slotToElicit ? data.slotToElicit : null,
                null,
                false
              );
              this.update(userMessage1);
            }
          
          }
        }
    
      },
      (err) => console.log(err),
      () => console.log("complete")
    );
    // webSocke
    this.subject.next({
      topic: "aws/subscribe",
      content: { topics: ["aws/chat"] }, 
    });
  }
  replaceLinksInMessage(srcMessage, linkTitle = null): string {
    console.log("srcMessage", srcMessage)
    let values = srcMessage.split(" ");
    if (!linkTitle) {
      linkTitle = this.isArabic(srcMessage) ? 'رابط' : 'link';
    }
    values.forEach((ele, index) => {
      if (ele.includes("http")) {
        values[index] = '<a href="' + ele + '" target="_blank">' + linkTitle + "</a>";
      }
    });
    values = values.join(" ");
    console.log("values", values)
    return values;
  }
  isArabic(message): boolean {
    return this.arabic.test(message);
  }
  disconnectConn() {
    this.subject.complete();
  }

  closeAgentConnection(data): Observable<any> {
    let endConn = {
      message: "stop agent",
      sessionId:"",
      chatbotId: this.chatbotId,
      userInfo: this.userDetails,
      event:data
    };
    const apiUrl = this.apiUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post<any>(apiUrl, endConn, httpOptions);
  }
clearmsg(){
  this.stopAgent=true;
}
checkAgent(){
 return this.agentSwitch;
}

} 