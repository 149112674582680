import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatbotIconComponent } from './chatbot-icon/chatbot-icon.component';
import { MessageComponent } from './body/message/message.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import {ButtonOptionsComponent} from './body/button-options/button-options.component'; 
import { CardSliderComponent } from './body/card-slider/card-slider.component';
import { ButtonsSliderComponent } from './body/buttons-slider/buttons-slider.component';
import { TabledataComponent } from './body/tabledata/tabledata.component';
import { BottomButtonsOptionsComponent } from './body/bottom-buttons-options/bottom-buttons-options.component';
import { UnscrollableTableComponent } from './body/unscrollable-table/unscrollable-table.component';
import { ButtonRatingComponent } from './body/button-rating/button-rating.component';
import { SliderRatingComponent } from './body/slider-rating/slider-rating.component';
import { RateWithTextComponent } from './body/rate-with-text/rate-with-text.component';
import { EmjoRatingComponent } from './body/emjo-rating/emjo-rating.component';
import { NumberRatingComponent } from './body/number-rating/number-rating.component';
import { SalaryboxComponent } from './body/salarybox/salarybox.component';
import { BottomButtonSlidersComponent } from './body/bottom-button-sliders/bottom-button-sliders.component';
import { CardSliderButtonComponent } from './body/card-slider-button/card-slider-button.component';
import { StarRatingModalComponent } from './body/star-rating-modal/star-rating-modal.component';
// import { OwlModule } from "ngx-owl-carousel";
import { CarouselModule } from 'ngx-owl-carousel-o';



@NgModule({
    declarations: [HeaderComponent, FooterComponent, ChatbotIconComponent, MessageComponent, ButtonOptionsComponent, CardSliderComponent, ButtonsSliderComponent,StarRatingModalComponent, TabledataComponent, BottomButtonsOptionsComponent, UnscrollableTableComponent, ButtonRatingComponent, SliderRatingComponent, RateWithTextComponent, EmjoRatingComponent, NumberRatingComponent, SalaryboxComponent, BottomButtonSlidersComponent, CardSliderButtonComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PickerModule,CarouselModule,],
  exports: [HeaderComponent, FooterComponent, ChatbotIconComponent, MessageComponent, StarRatingModalComponent],
})
export class ComponentsModule {}
