import { Component, OnInit } from '@angular/core';
import {ScrollService} from '../../../services/scroll.service';

@Component({
  selector: 'app-card-slider',
  templateUrl: './card-slider.component.html',
  styleUrls: ['./card-slider.component.css']
})
export class CardSliderComponent implements OnInit {

  constructor(private scrollService: ScrollService) { }

  ngOnInit() {
  }
  scroll(target: HTMLElement, direction) {
    // const increment = target.scrollLeft + target.offsetWidth / 2;
    this.scrollService.scroll(target, direction, 250);
  }
}
