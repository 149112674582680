import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ChatService, Message } from 'src/app/chat.service';
import { ToggleService } from 'src/app/services/toggle.service';

@Component({
  selector: 'app-star-rating-modal',
  templateUrl: './star-rating-modal.component.html',
  styleUrls: ['./star-rating-modal.component.css']
})
export class StarRatingModalComponent implements OnInit {

  starNon = [1, 1, 1, 1, 1];
  stars: [] = [];
  @Output() clearMessages = new EventEmitter<any>();
  rating: any;
  constructor(private chat: ChatService, private readonly toggleService: ToggleService) {
  }

  ngOnInit() {
  }

  triggerRatingModule(starRatingModuleTriggerCount){
    if(starRatingModuleTriggerCount == 0){
      let element: HTMLElement = document.getElementById("starRating") as HTMLElement
      console.log("In triggerRatingModule 1")
      element.click()
    }
  }
   
  postUserRating(){
    // this.chat.postUserRating(this.rating).subscribe((data)=>{
    //   console.log("ratingRes", data)
    // })
    let element: HTMLElement = document.getElementById("closeModel") as HTMLElement
    element.click()
    const botMessage = new Message(
      "Thanks for rating our service, Goodbye",
      "user",
      new Date().getTime(),
      null,
      null,
      null,
      true,null
    );
    this.chat.update(botMessage)
    this.countStarts(-1)
    setTimeout(()=>{
      this.clearMessages.emit();
      this.toggleService.toggleChatbot(2);
    }, 4000)
  }

  countStarts(index, type: string = null) {
    console.log(
        'Rating',
        index+1
    );
    this.rating= index+1
    if (type == 'NON') {
      console.log('in NON');
      // @ts-ignore
      if (this.starNon.length != 5) {
        index = this.stars.length + index;
      }
      let target_index = index + 1;
      this.starNon = [];
      for (let i = 0; i < 5 - target_index; i++) {
        // @ts-ignore
        this.starNon.push(1);
      }
      this.stars = [];
      for (let i = 0; i <= index; i++) {
        // @ts-ignore
        this.stars.push(1);
      }
      console.log(target_index);
    } else {
      console.log('OUT NON');

      if (this.stars.length != index+1){
        // @ts-ignore
        if (this.stars.length != 5) {
          index = this.starNon.length + index - 1;
        }
        let target_index = index;
        this.stars = [];
        // @ts-ignore
        if (this.starNon.length == 2 &&target_index == 2) {
          target_index = target_index - 1;
        }
        else if (this.starNon.length == 3  &&target_index == 2 ) {
          target_index = target_index - 2;
        }
        else if (this.starNon.length == 2  &&target_index == 1 ) {
          target_index = target_index - 1;
          console.log("inside Condition");
        }
        console.log("star non length",this.starNon.length);
        for (let i = 0; i <= target_index; i++) {
          // @ts-ignore
          this.stars.push(1);
        }

        this.starNon = [];
        for (let i = 0; i < 5 - (target_index +1) ; i++) {
          // @ts-ignore
          this.starNon.push(1);
        }
        console.log("Target",target_index);
      }

    }
  }

}
