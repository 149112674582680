import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
declare var $: any; 

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FooterComponent implements OnInit {
  @Input() inputValue: string;
  @Output() sendMsgEv = new EventEmitter();
  @ViewChild("inputRef", { static: true }) inputRef: ElementRef;
  @Output() inputValueChange = new EventEmitter();
  @Output() fileUploadEv = new EventEmitter();
  showEmoj = true;
  lang='';
 canSendMessage = true;
 @Input() chatbot_lang;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['lang']) {
        this.chatbot_lang = params['lang'];
      }else{
        this.chatbot_lang='ar';
      }
    });
 

  }

  change(newValue) {
    this.inputValue = newValue;
    this.inputValueChange.emit(newValue);
  }

  async sendMessage() {
    this.canSendMessage = false;

    // Introduce a delay using a Promise
    await new Promise(resolve => setTimeout(resolve, 1000));
  
    this.canSendMessage = true;
    this.sendMsgEv.emit(true);
    this.inputRef.nativeElement.value = null;
  }
  
  importFile(files: File[]) {
    this.fileUploadEv.emit(files);
}
  addEmoji(event) {

    if (!this.inputValue) {
      this.inputValue = "";
    }

    this.inputValue = this.inputValue + event.emoji.native;
    $("#text").val($("#text").val() + event.emoji.native);
    this.inputValueChange.emit(this.inputValue);
    this.showEmoj = !this.showEmoj;

    console.log(event)
    console.log(this.inputValue)

  }

  switchEmoj() {
    this.showEmoj = !this.showEmoj;
    console.log('enter');
    if (this.showEmoj == true) {
      $(".float-icon").css("z-index", "99");
    } else {
      console.log("open it ");
      $(".float-icon").css("z-index", "0");
    }
  }
}
