import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bottom-buttons-options',
  templateUrl: './bottom-buttons-options.component.html',
  styleUrls: ['./bottom-buttons-options.component.css']
})
export class BottomButtonsOptionsComponent implements OnInit {
  buttons = [
    { title: 'Write to us', isClickable: false},
    { title: 'Call Us', isClickable: false},
    { title: 'Credit Card Increase', isClickable: false},
    { title: 'Credit Card Decrease', isClickable: false}
  ];
  constructor() { }

  ngOnInit() {
  }
}
