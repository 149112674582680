import { Component, OnInit } from '@angular/core';
import {ScrollService} from '../../../services/scroll.service';

@Component({
  selector: 'app-buttons-slider',
  templateUrl: './buttons-slider.component.html',
  styleUrls: ['./buttons-slider.component.css']
})
export class ButtonsSliderComponent implements OnInit {

  constructor(private scrollService: ScrollService) { }

  buttons = [
    {icon: './assets/imgs/Vector.png', title: 'Education', isClickable: false},
    {icon: './assets/imgs/Vector.png', title: 'Education', isClickable: false},
    {icon: './assets/imgs/Vector.png', title: 'Education', isClickable: false},
    {icon: './assets/imgs/Vector.png', title: 'Education', isClickable: false},
    {icon: './assets/imgs/Vector.png', title: 'Education', isClickable: false},
    {icon: './assets/imgs/Vector.png', title: 'Education', isClickable: false},
    {icon: './assets/imgs/Vector2.png', title: 'Online', isClickable: false}
  ];

  currentButton = 1;

  ngOnInit() {
  }
  setEduction(index) {
    this.buttons[index].isClickable = true;
  }
  scroll(target: HTMLElement, direction) {
    // const increment = target.scrollLeft + target.offsetWidth / 2;
    if (direction === 'right') {
      if (this.currentButton !== this.buttons.length) {
        this.currentButton += 1;
      }
    } else {
      if (this.currentButton !== 1) {
        this.currentButton -= 1;
      }
    }
    this.scrollService.scroll(target, direction, 200);
  }
}
