import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unscrollable-table',
  templateUrl: './unscrollable-table.component.html',
  styleUrls: ['./unscrollable-table.component.css']
})
export class UnscrollableTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
