import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-options',
  templateUrl: './button-options.component.html',
  styleUrls: ['./button-options.component.css']
})
export class ButtonOptionsComponent implements OnInit {
  buttons = [
    {icon: './assets/imgs/Vector.png', title: 'Education', isClickable: false},
    {icon: './assets/imgs/Vector2.png', title: 'Online', isClickable: false}
  ];
  constructor() { }

  ngOnInit() {
  }
  setEduction(index) {
    this.buttons[index].isClickable = true;
  }
}
