import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { ToggleService } from "src/app/services/toggle.service";
declare var $: any;

@Component({
  selector: "app-chatbot-icon",
  templateUrl: "./chatbot-icon.component.html",
  styleUrls: ["./chatbot-icon.component.css"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ChatbotIconComponent implements OnInit {
  @Output() toggleChatBotEv = new EventEmitter<number>();
  @Output() clearMessages = new EventEmitter<any>();
  @Input() imgName: string;
  closeBtnShow: boolean = false;
  showHelpText: boolean = false;
  close: boolean = false;
  chatbot_lang = 'en';
  
  constructor(private readonly toggleService: ToggleService) {
    this.toggleService.watchIconStatus().subscribe((data) => {
      this.closeBtnShow = data;
      
      if (!this.closeBtnShow) {

        setTimeout(() => {
          if (!this.close) {
            this.showHelpText = true;
          }
        }, 5000);
        setTimeout(() => {
      
          this.showHelpText = false;
        }, 15000);
      } else {
        this.showHelpText = false;
      }
    });
  }

  ngOnInit() {
 setTimeout(() => {
  if (localStorage.getItem('chatbot_lang') !== null) {
    this.chatbot_lang = localStorage.getItem('chatbot_lang');
  }
 }, 1500);
  }
  toggleChatBot(num: number) {
    this.showHelpText = false;
    this.close = true;
    if (num === 2) {
      this.clearMessages.emit();
      num = 0;
    }
    if (num === 1) {
      $(".help-text").hide();
      parent.postMessage({ close_welcome : true }, '*');
      this.closeBtnShow = true;
    }
    this.toggleService.toggleChatbot(num);
  }
  closeMessage() {
    $(".help-text").hide();
    parent.postMessage({ close_welcome : true }, '*');
  }
}
