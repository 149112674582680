import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

declare var $: any;

@Injectable({
  providedIn: "root",
})
export class ToggleService {
  private iconStatus = new BehaviorSubject<boolean>(false);

  constructor() {}
  watchIconStatus(): Observable<any> {
    return this.iconStatus.asObservable();
  }
  toggleChatbot(num: number) {
    $(".welcome-message-toggle").hide();
    if (num == 1) {
      // $(".chatbot-toggle-icon").hide();
      this.iconStatus.next(true);
      $(".chatbot-container").show();
      parent.postMessage({ open: true }, "*");
    } else {
      // $(".chatbot-toggle-icon").show();
      $(".chatbot-container").hide();
      this.iconStatus.next(false);

      parent.postMessage({ open: false }, "*");
    }
  }
}
