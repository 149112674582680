import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {ScrollService} from '../../../services/scroll.service';

@Component({
  selector: 'app-card-slider-button',
  templateUrl: './card-slider-button.component.html',
  styleUrls: ['./card-slider-button.component.css']
})
export class CardSliderButtonComponent implements OnInit {

  constructor(private scrollService: ScrollService) { }

  @Input() imageDescription
  @Output() sendMessageEV = new EventEmitter()
  currentCard = 1;
  
  ngOnInit() {
  }
  scroll(target: HTMLElement, direction) {
    // const increment = target.scrollLeft + target.offsetWidth / 2;
    if (direction === 'right') {
      if (this.currentCard !== this.imageDescription.length) {
        this.currentCard += 1;
      }
    } else {
      if (this.currentCard !== 1) {
        this.currentCard -= 1;
      }
    }
    this.scrollService.scroll(target, direction, 200);
  }

  sendMessage(value){
    this.sendMessageEV.emit(value)
  }
  // isArabic(text: string): boolean {
  //   const arabicRegex = /[\u0600-\u06FF]/;
  //   return arabicRegex.test(text);
  // }

  isArabic(message) : boolean{
    if (localStorage.getItem('chatbot_lang') !== null) {
      var chatbot_lang = localStorage.getItem('chatbot_lang');
      // console.log(this.chatbot_lang,'lan')
      // this.arabic = this.chatbot_lang
    }
    if ( chatbot_lang == "ar") {
      return true;
    }else if (chatbot_lang == "en") {
      return false;
    }
   }

}
