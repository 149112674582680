import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  Input,
  EventEmitter,
} from "@angular/core";
import { ChatService, Message } from "src/app/chat.service";
import { ToggleService } from "src/app/services/toggle.service";
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  constructor(private readonly toggleService: ToggleService,
    private chat: ChatService,) {}
  @Output() toggleChatBotEv = new EventEmitter<number>();
  @Output() clearMessages = new EventEmitter<any>();
  @Output() sendHelloMessageEv = new EventEmitter<void>();

  @Input() chatbot_lang;
  closeCount:number = 0
  ngOnInit() {}
  clearChatBot(num: number) {
        this.clearMessages.emit();
        this.closeCount=this.closeCount+1 
        this.toggleService.toggleChatbot(0);
      
  }
  toggleChatBot(num: number) {
    this.toggleService.toggleChatbot(num);
    $('.chatbot-toggle-icon').show();
    $('.chatbot-container').hide();
    parent.postMessage({ open : false }, '*');
  }
}
