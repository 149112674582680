import { Component, OnInit } from '@angular/core';
import {ScrollService} from '../../../services/scroll.service';

@Component({
  selector: 'app-slider-rating',
  templateUrl: './slider-rating.component.html',
  styleUrls: ['./slider-rating.component.css']
})
export class SliderRatingComponent implements OnInit {

  constructor(private scrollService: ScrollService) { }

  buttons = [
    { title: '1', isClickable: false, disable: false},
    {title: '2', isClickable: false, disable: false},
    {title: '3', isClickable: false, disable: false},
    {title: '4', isClickable: false, disable: false},
    {title: '5', isClickable: false, disable: false},
    {title: '6', isClickable: false, disable: false},
    {title: '7', isClickable: false, disable: false},
    {title: '8', isClickable: false, disable: false},
    {title: '9', isClickable: false, disable: false},
    {title: '10', isClickable: false, disable: false},
  ];

  ngOnInit() {
  }
  setEduction(index) {
    this.buttons[index].isClickable = true;
    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].disable = true;
    }
  }
  scroll(target: HTMLElement, direction) {
    // const increment = target.scrollLeft + target.offsetWidth / 2;
    this.scrollService.scroll(target, direction, 250);
  }

}
