import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  OnChanges,
  ViewEncapsulation
} from "@angular/core";      
import { Message } from "src/app/models/message-model";
import { Subscription } from 'rxjs';
import { ChatService } from "../../../chat.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import { Router, ActivatedRoute } from "@angular/router";


@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.css","../../../styling.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MessageComponent implements OnInit, OnDestroy, OnChanges{
  subscription: Subscription;
  @Input() message: Message;
  // @Input() lastMsg:Message
  @Input() lastMsg: any = {
    sessionAttributes: {},
    showInput: true,
    sessionDetails: {},
    userInfo: {}
  };
  @Input() intentName: string;
  @Input() showBotIcon: any;
  sessionAttributes:any;
  typingIndicator:boolean = true
  @Output() suggestionMsgEv = new EventEmitter<any>();
  @Output() triggerRatingModuleEV = new EventEmitter<any>()
  isLastResponseNBI: boolean;
  showStarRatingModule: boolean;
  chatbot_lang = "en";
  constructor(private chatService: ChatService,private route: ActivatedRoute) {
    // this.subscription = this.chatService.typingIndicatorFn().subscribe(typing => {

    //   this.typingIndicator = typing
    // });
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    items: 2,
    dots: false,
    navSpeed: 700,
    navText: ["<", ">"],
    responsive: {
      992: {
        items: 1.5,
      },
      768: {
        items: 1.5,
      },
      400: {
        items: 1.5,
      },
      0: {
        items: 1.5,
      },
    },
    nav: true,
  }; 
  phone = "";    
  name = "";  
  email = "";
  sessionId: any = new Date()
  hasButtonBeenClicked = false;
  ngOnInit() {
    console.log("message22",this.message.content)

    this.route.queryParams.subscribe((params) => {
      if (params['lang']) {
        this.chatbot_lang = params['lang'];
        localStorage.setItem('chatbot_lang', this.chatbot_lang);
      }
    });
    
    if (this.message.hasOwnProperty("content") && this.message.content == "Thank you for chatting with us. Goodbye."){
      this.triggerRatingModule()
    } else {
      this.showStarRatingModule = false
    }
   
    setTimeout(()=>{
      console.log("line 106",this.lastMsg);
    },2000)
    

    if(this.lastMsg.sessionDetails){
      console.log("last message -> ",this.lastMsg);
      if (this.lastMsg.userInfo && Object.keys(this.lastMsg.userInfo).length === 0 && this.lastMsg.sessionDetails.slots.email != null) {
        localStorage.setItem("chatbot_lang", "en")
        localStorage.setItem('phone', this.lastMsg.sessionDetails.slots.phone);
        console.log("set user info", this.phone)
        localStorage.setItem("name", this.lastMsg.sessionDetails.slots.name)
        localStorage.setItem("email", this.lastMsg.sessionDetails.slots.email)
      }
      if (localStorage.getItem("phone") && this.lastMsg.userInfo.email == null && this.lastMsg.sessionState.sessionAttributes.email == null) {
        this.sessionId = localStorage.getItem("sessionId")
        console.log("sessionId reg", this.sessionId)
        this.sessionId = this.sessionId.toString()
        this.phone = localStorage.getItem("phone")
        this.name = localStorage.getItem("name")
        this.email = localStorage.getItem("email")
      } else if (this.lastMsg.sessionDetails && this.lastMsg.sessionDetails.userInfo && this.lastMsg.sessionDetails.userInfo.hasOwnProperty('email') && this.lastMsg.sessionDetails.userInfo.email != null) {
        localStorage.setItem("chatbot_lang", this.chatbot_lang)
        localStorage.setItem('phone', this.lastMsg.sessionDetails.userInfo.phone);
        localStorage.setItem("name", this.lastMsg.sessionDetails.userInfo.name)
        localStorage.setItem("email", this.lastMsg.sessionDetails.userInfo.email)
      }
    }else{

    if (this.lastMsg.sessionState && this.lastMsg.sessionState.sessionAttributes.email != null) {
      console.log("test 138",this.lastMsg);
      localStorage.setItem("chatbot_lang", this.chatbot_lang)
      localStorage.setItem('phone', this.lastMsg.sessionState.sessionAttributes.phone);
      console.log("phone reg", this.phone)
      localStorage.setItem("name", this.lastMsg.sessionState.sessionAttributes.name)
      localStorage.setItem("email", this.lastMsg.sessionState.sessionAttributes.email)
      console.log("phone reg", this.lastMsg.sessionState.sessionAttributes.email)
    }

    if (localStorage.getItem("phone") && this.lastMsg.userInfo.email == null && this.lastMsg.sessionState.sessionAttributes.email == null) {
      this.sessionId = localStorage.getItem("sessionId")
      console.log("sessionId reg", this.sessionId)
      this.sessionId = this.sessionId.toString()
      this.phone = localStorage.getItem("phone")
      this.name = localStorage.getItem("name")
      this.email = localStorage.getItem("email")

   
    } else if (this.lastMsg.userInfo.hasOwnProperty('email') && this.lastMsg.userInfo.email != null) {
      //this.sessionId = (Math.floor(new Date().valueOf() * Math.random())).toString();
      localStorage.setItem("chatbot_lang", this.chatbot_lang)
      localStorage.setItem('phone', this.lastMsg.userInfo.phone);
      // console.log("phone reg", this.phone)
      localStorage.setItem("name", this.lastMsg.userInfo.name)
      localStorage.setItem("email", this.lastMsg.userInfo.email)
    }
    
    // if (this.lastMsg.sessionState &&  this.lastMsg.sessionState.intent && this.lastMsg.sessionState.intent.slots && this.lastMsg.sessionState.intent.slots.hasOwnProperty('email') && this.lastMsg.sessionState.intent.slots.email.value.originalValue != null) {
    //   console.log("test",this.lastMsg);   
    //   localStorage.setItem("chatbot_lang", this.chatbot_lang)       
    //   localStorage.setItem('phone', this.lastMsg.sessionState.intent.slots.phone.value.originalValue);
    //   console.log("phone reg", this.phone)
    //   localStorage.setItem("name", this.lastMsg.sessionState.intent.slots.name.value.originalValue)
    //   localStorage.setItem("email", this.lastMsg.sessionState.intent.slots.email.value.originalValue)
    //   console.log("phone reg", this.lastMsg.sessionState.sessionAttributes.email)
    // }

  }


    // if (localStorage.getItem("phone")) {
    //   this.phone = localStorage.getItem("phone")
    //   this.name = localStorage.getItem("name")
    //   this.email = localStorage.getItem("email")
    // }
 
   
    
  }

  triggerRatingModule(){
    this.triggerRatingModuleEV.emit()
    // this.showStarRatingModule = false
    // let element: HTMLElement = document.getElementById("starRating") as HTMLElement
    // element.click() 
  }

  ngOnChanges(changes) {
    console.log("changes",changes)
    // changes.prop contains the old and the new value...
  } 
  sendMessage(msg, divId, slotToElicit) {
    this.typingIndicator= true
    const suggestionMsgDetails = {
      msg,
      divId,
      slotToElicit,
      sessionAttributes:this.sessionAttributes
    };
    this.suggestionMsgEv.emit(suggestionMsgDetails);
    
  }
  sendMessage1(msg, divId, slotToElicit,name,text) {

  this.typingIndicator= true
    const suggestionMsgDetails = {
      msg,
      divId,
      slotToElicit,
      sessionAttributes:this.sessionAttributes
    };
    document.getElementById(name).style.background = "#182F7C";
    document.getElementById(text).style.color = "#ffffff";
    this.suggestionMsgEv.emit(suggestionMsgDetails);
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
   convertToHTMLBlock(linkString) {
    console.log("JSON.parse(linkString) ",JSON.parse(linkString))
    return JSON.parse(linkString);
}
isFirstWordLink(text) {
  // Create a temporary element to parse the HTML string
  console.log("text -> ",text)
  var tempElement = document.createElement('div');
  tempElement.innerHTML = text.trim();

  // Get the first word
  var firstWord = tempElement.firstChild.textContent.split(' ')[0];

  // Check if it starts with <a href
  return firstWord.startsWith('<a') && firstWord.endsWith('href');
}
  public checkHelloString(str) {
    if (this.chatbot_lang == "ar") {
      return str.replace(/مرحبا whatsapp:\+[0-9]+/i, "مرحبا");
    } else {
      return str.replace(/hello \+[0-9]+/i, "Hello");
    }
  }

extractNameAndUrl(htmlString) {
  // Create a temporary element to parse the HTML string
  var tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  // Access the <a> tag element
  var aTag = tempElement.querySelector('a');

  // Extract name and URL
  var name = aTag.innerText;
  var url = aTag.getAttribute('href');
console.log("name -> ",name)
console.log("url -> ",url)
  return { name: name, url: url };
}


  public checkTextLimetation(str) {

      if(str.includes("Can I find more information in the dashboards and")){
        return str.replace(str,"Can I find more information in the dashboards and reports?");

      }else if(str.includes("With what type of services does saned help employ")){
        return str.replace(str,"With what type of services does saned help employees?");

      }else if(str.includes("What data do I need to add to set up my employees")){
        return str.replace(str,"What data do I need to add to set up my employees?")

      }else if(str.includes("What do I do about indirect staff employed throug")){
        return str.replace(str,"What do I do about indirect staff employed through agencies?")

      }else if(str.includes("Do I need to import an organization chart into sa")){
        return str.replace(str,"Do I need to import an organization chart into saned?")
    
      }else if(str.includes("Does saned include Performance Management for emp")){
        return str.replace(str,"Does saned include Performance Management for employees?")

      }else if(str.includes("Does saned work out GOSI in the payroll calculati")){
        return str.replace(str,"Does saned work out GOSI in the payroll calculation?")

      }else if(str.includes("How long does it take to set up my company for sa")){
        return str.replace(str,"How long does it take to set up my company for saned HR?")
      }
      
      else if(str.includes("What are the default options for the policies in")){
        return str.replace(str,"What are the default options for the policies in saned HR?")
      }
    
      else if(str.includes("Do the HR Administrators need to be experienced H")){
        return str.replace(str,"Do the HR Administrators need to be experienced HR professionals?")
      }
      
      else if(str.includes("What are the default options for the policies in")){
        return str.replace(str,"What are the default options for the policies in saned HR?")
      }

      

      else if(str.includes("هل يتم يتم وضع السياسات المتوافقة مع قانون العمل")){
        return str.replace(str,"هل يتم يتم وضع السياسات المتوافقة مع قانون العمل بشكل افتراضي في ساند للموارد البشرية؟")
      }

      else if(str.includes("هل يمكن في ساند حساب مرتبات العملاء الخاضعين لنظا")){
        return str.replace(str,"هل يمكن في ساند حساب مرتبات العملاء الخاضعين لنظام التأمينات الاجتماعية GOSI؟")
      }

      else if(str.includes("كيف تقوم ساند حساب المرتبات للعملاء الخاضعين لنظا")){
        return str.replace(str,"كيف تقوم ساند حساب المرتبات للعملاء الخاضعين لنظام التأمينات الاجتماعية GOSI؟")
      }

      else if(str.includes("كيف يمكن القيام بإجراءات خاصة من أجل دفع الرواتب")){
        return str.replace(str,"كيف يمكن القيام بإجراءات خاصة من أجل دفع الرواتب؟")
      }

      else if(str.includes("هل بوابة و تطبيق ساند موجودين باللغتين العربية وا")){
        return str.replace(str,"هل بوابة و تطبيق ساند موجودين باللغتين العربية والإنجليزية؟")
      }

      else if(str.includes("متى يمكنني البدء باستخدام منصة ساند للموارد البشر")){
        return str.replace(str,"متى يمكنني البدء باستخدام منصة ساند للموارد البشرية في شركتي عند اشتراكي بها؟")
      }

      else if(str.includes("هل أحتاج لتدريب مسبق للتمكن من استخدام منصة ساند")){
        return str.replace(str,"هل أحتاج لتدريب مسبق للتمكن من استخدام منصة ساند للموارد البشرية؟")
      }

      else if(str.includes("هل يجب على المسؤولين في منصة ساند للموارد البشرية")){
        return str.replace(str,"هل يجب على المسؤولين في منصة ساند للموارد البشرية أن يكونوا متخصصين في مجال الموارد البشرية؟")
      }

      else if(str.includes("ما هي البيانات التي أحتاج إضافتها لإعداد الموظفين")){
        return str.replace(str,"ما هي البيانات التي أحتاج إضافتها لإعداد الموظفين لدي؟")
      }

      else if(str.includes("ماذا أفعل من أجل الموظفين غير المباشرين الذين يتم")){
        return str.replace(str,"ماذا أفعل من أجل الموظفين غير المباشرين الذين يتم توظيفهم من خلال الوكالات؟")
      }

      else if(str.includes("هل يمكن لساند مساعدتي في إدارة طلبات ومقابلات الت")){
        return str.replace(str,"هل يمكن لساند مساعدتي في إدارة طلبات ومقابلات التوظيف؟")
      }

      else if(str.includes("هل تقوم ساند أيضاً بالمساعدة في إدارة الأداء للمو")){
        return str.replace(str,"هل تقوم ساند أيضاً بالمساعدة في إدارة الأداء للموظفين؟")
      }

      else if(str.includes("هل يمكنني العثور على مزيد من المعلومات في لوحات ا")){
        return str.replace(str,"هل يمكنني العثور على مزيد من المعلومات في لوحات التقارير؟")
      }

      else if(str.includes("هل يمكنني رؤية لوحات التقارير على من خلال تطبيق ا")){
        return str.replace(str,"هل يمكنني رؤية لوحات التقارير على من خلال تطبيق الهاتف الجوال؟")
      }
      
      else{
        return str;
      }
    // }
  }

  isArabic(message) : boolean{
    if (localStorage.getItem('chatbot_lang') !== null) {
      this.chatbot_lang = localStorage.getItem('chatbot_lang');
    }
    if ( this.chatbot_lang == "ar") {
      return true;
    }else if (this.chatbot_lang == "en") {
      return false;
    }
   }
   isArabicLang(str: string) {
    // Regular expression pattern to match Arabic characters
    var arabicPattern = /[\u0600-\u06FF]/;
    console.log("check if the language equal to ar",str)
    return arabicPattern.test(str.split(" ")[0]);
  }

}
