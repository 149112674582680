import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-number-rating',
  templateUrl: './number-rating.component.html',
  styleUrls: ['./number-rating.component.css']
})
export class NumberRatingComponent implements OnInit {

  constructor() { }

  buttons = [
    { title: '1', isClickable: false, disable: false},
    {title: '2', isClickable: false, disable: false},
    {title: '3', isClickable: false, disable: false},
    {title: '4', isClickable: false, disable: false},
    {title: '5', isClickable: false, disable: false},
  ];

  ngOnInit() {
  }
  setEduction(index) {
    this.buttons[index].isClickable = true;
    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].disable = true;
    }
  }

}
