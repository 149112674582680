import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ChatService, Message } from "./chat.service";
import { Observable } from "rxjs";
import { scan } from "rxjs/operators";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ToggleService } from "./services/toggle.service";


declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {


  testArr: { buttons: { link: string; text: string; page: string }[] } = { 
    buttons: [
      {
        link:
          "https://cdn.pixabay.com/photo/2018/06/17/20/35/chain-3481377_1280.jpg",
        text: "test",
        page: "test",
      },
      {
        link:
          "https://cdn.pixabay.com/photo/2018/06/17/20/35/chain-3481377_1280.jpg",
        text: "test",
        page: "test",
      },

      {
        link:
          "https://cdn.pixabay.com/photo/2018/06/17/20/35/chain-3481377_1280.jpg",
        text: "test",
        page: "test",
      },
      {
        link:
          "https://cdn.pixabay.com/photo/2018/06/17/20/35/chain-3481377_1280.jpg",
        text: "test",
        page: "test",
      },
      {
        link:
          "https://cdn.pixabay.com/photo/2018/06/17/20/35/chain-3481377_1280.jpg",
        text: "test",
        page: "test",
      },
    ],
  };
  title = "Chatbot";
  bigRobot = true;
  showEmoj = true;
  userForm = {
    email: null,
    name: null,
    phone: null,
  };
  phone = "";
  name = "";
  email = "";
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    items: 2,
    dots: false,
    navSpeed: 700,
    navText: ["<", ">"],
    responsive: {
      992: {
        items: 1.5,
      },
      768: {
        items: 1.5,
      },
      400: {
        items: 1.5,
      },
      0: {
        items: 1.5,
      },
    },
    nav: true,
  };
  images = [
    {
      path:
        "https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    },
    {
      path:
        "https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    },
    {
      path:
        "https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    },
    {
      path:
        "https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    },
    {
      path:
        "https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    },
    {
      path:
        "https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    },
    {
      path:
        "https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    },
  ];
  showCarousal = false;
  scrollDown = false;
  currentBotMessageIndex = 0;
  starRatingModuleTriggerCount: number = 0;
  greet = '';
  constructor(
    private chat: ChatService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private readonly toggleService: ToggleService
  ) { }

  //@ViewChild("scrollElement", null) scroollProp: ElementRef;
  sessionId: any = new Date()
  uploadedFile: File;
  messages: Observable<Message[]>;
  formValue: string = "";
  lastMsg: any = {
    sessionAttributes: {},
    showInput: true,
    sessionDetails: {},
  };
  hasButtonBeenClicked = false;
  minDate = new Date();
  maxDate = new Date(2019, 12, 0);
  types: string[] = [];
  sessionAttrib: any = {};
  sessionDetails: any = {};
  userId: string = "user";
  showIndicator = false;
  uploadFile = File;
  userDetails = {};
  registerForm: FormGroup;
  submitted = false;
  count = 0;
  chatbot_lang = "ar";
  sessionState: any = null;
  ngOnInit(): void {
    this.sessionIdFn();


    setTimeout(() => {
      this.route.queryParams.subscribe((params) => {
       console.log("check agent fun -> ",localStorage.getItem('agentSwitch'))
       if(localStorage.getItem('agentSwitch') == 'true'){
         this.clearMessages();
         localStorage.setItem('agentSwitch', 'false');
       }else{
         if (params['lang'] == undefined){
           this.chatbot_lang = 'ar';
           localStorage.setItem('chatbot_lang', this.chatbot_lang);
          }
         console.log("check params",(params['lang'] ));
         if(params['lang'] && params['lang'] == 'en'){
           this.chatbot_lang ='en';
           localStorage.setItem('chatbot_lang', this.chatbot_lang);
           this.sendMessage('Hello ' + this.phone, null, null, null, false);
         }else{
           this.chatbot_lang = 'ar';
           localStorage.setItem('chatbot_lang', this.chatbot_lang);
           this.sendMessage('مرحبا', null, null, null, false);
         }
       }
  
     });
    }, 1000);
    
    // Check if 'chatbot_lang' is already stored in localStorage
    if (localStorage.getItem('chatbot_lang') !== null) {
      // Retrieve 'chatbot_lang' from localStorage
      this.chatbot_lang = localStorage.getItem('chatbot_lang');
    } else {
      // If 'chatbot_lang' is not in localStorage, set the default value
      localStorage.setItem('chatbot_lang', this.chatbot_lang);
    }
    
    if (localStorage.getItem("phone")) {
      this.sessionId = localStorage.getItem("sessionId")
      console.log("sessionId reg", this.sessionId)
      this.sessionId = this.sessionId.toString()
      this.phone = localStorage.getItem("phone")
      this.name = localStorage.getItem("name")
      this.email = localStorage.getItem("email")
      this.userDetails = {
        "phone": this.phone,
        "name": this.name,
        "email": this.email,
        "sessionId": this.sessionId
      }
    } else {
      console.log("sessionId reg", this.sessionId)
      localStorage.setItem("phone", this.phone)
      localStorage.setItem("name", this.name)
      localStorage.setItem("email", this.email)
    }


   
    $("#OpenImgUpload").click(function () {
      $("#imgupload").trigger("click");
    });

    this.sessionAttrib = {};
    this.chat.conversation
      .asObservable()
      .subscribe((data) => console.log(data));


    this.messages = this.chat.conversation.asObservable().pipe(
      scan((acc, val, i) => {
        console.log("acc", acc)
        console.log("val", val)
        let elementIndex = 0;
        for (let index = 0; index < acc.length; index++) {
          if (acc[index].sentBy === 'bot' && acc[index].content) {
            elementIndex = index;
            break;
          }
        }
        let arr = [];
        if (val.length) {
          arr = val.concat(acc);
        } else {
          arr = val.concat([]);
        }

        for (let index = 0; index < arr.length; index++) {
          let nextIndex = index + 1;
          if (index !== arr.length - 1) {
            if (arr[nextIndex].content === 'image' || arr[nextIndex].content === 'table') {
              nextIndex += 1;
            }
            if ((arr[index].sentBy === 'user' && arr[nextIndex].sentBy === 'user') || (arr[index].sentBy === 'bot' && arr[nextIndex].sentBy === 'bot')) {
              arr[index]['showImage'] = false;
            } else {
              arr[index]['showImage'] = true;
            }
          } else {
            arr[index]['showImage'] = true;
          }
        }

        if (!this.formValue) {
          setTimeout(() => {
            const container = $('#content');
            const scrollTo = $(`#message_${elementIndex + 1}`);
            console.log("test", container.offset(), "scroll to:", scrollTo.offset(), "container: ", container.scrollTop());

            if (scrollTo && container && scrollTo.offset() && container.offset()) {
              console.log("scroll is working", this.formValue)
              container.animate({
                scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
              });
              console.log("test2", container.offset().top, "scroll to:", scrollTo.offset().top, "container: ", container.scrollTop());
            }
            else {
              container.animate({
                scrollTop:  129.265625 -559.25
              });
            }
          }, 1);
        }

        return arr;

      })
    );
    // this.sendMessage('Hi', null);

    this.registerForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
    });
 
  }


  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    console.log(
      "Message123",
      this.f.firstName.value,
      this.f.lastName.value,
      this.f.email.value
    );
    this.sendMessage(
      "form " +
      this.f.firstName.value +
      " " +
      this.f.lastName.value +
      " " +
      this.f.email.value,
      null
    );
  }

  get f() {
    return this.registerForm.controls;
  }

  fireInputEvent() {
    $("input[type='file']").trigger("click");
  }


  checkHelloString(str) {
    if (this.chatbot_lang == "ar") {
      return str.replace(/مرحبا whatsapp:\+[0-9]+/i, "مرحبا");
    } else {
      return str.replace(/Hello whatsapp:\+[0-9]+/i, "Hello");
    }
  }

  sendMessage(
    msg: any = null,
    divId: string = null,
    slotToElicit: string = null,
    image: any = null,
    showMsg: any = null,
    sessionAttributes: any = null
  ) {
    this.bigRobot = false;
    this.currentBotMessageIndex = - 1;
    console.log("msg: ", msg);
    this.count++;
    if (msg) {
      this.formValue = msg;
    }

    if (this.sessionDetails) {
      slotToElicit = this.sessionDetails.slotToElicit;
    }

    if (this.formValue) {
      var regEx = /(^|\s)([0-9]{16}|[0-9]{13}|[0-9]{8})(\s|$)/
      var regEx16 = /(^|\s)[0-9]{4}(-|\s)[0-9]{4}(-|\s)[0-9]{4}(-|\s)[0-9]{4}(\s|$)/
      if (this.formValue.match(regEx)) {
        var str = this.formValue.match(regEx)[0]
        var regEx1 = /([0-9]{16}|[0-9]{13}|[0-9]{8})/
        var str1 = str.match(regEx1)[0]
        var star = str1;
        str = str.replace(regEx1, star)
        this.formValue = this.formValue.replace(regEx, str)
      } else if (this.formValue.match(regEx16)) {
        var str = this.formValue.match(regEx16)[0]
        str = str.replace(/[0-9]/g, '*')
        this.formValue = this.formValue.replace(regEx16, str)
      }
      this.showIndicator = true;
      if (
        this.userForm.email != null &&
        this.userForm.name != null &&
        this.userForm.phone != null
      ) {
        this.sessionDetails.sessionAttributes.formValue = this.userForm;
        this.userForm = {
          email: null,
          name: null,
          phone: null,
        };
      }
      this.sessionAttrib = sessionAttributes
      console.log("sessionId", this.sessionId)
      console.log("formValue -> ", this.formValue)
      this.chat
        .converse(
          this.formValue,
          this.sessionId,
          this.sessionState,
          this.userDetails,
          this.sessionDetails,
          this.sessionAttrib,
          slotToElicit,
          image,
          showMsg
        )
        .then((data) => {
          console.log("userInfo: ", data);

          console.log(data)
          this.sessionState = data.sessionState ? data.sessionState : null
          this.showIndicator = false;
          this.lastMsg = data;
          this.sessionDetails = data.sessionDetails;
          this.types = [];
          if (data.sessionDetails) {
            this.sessionAttrib = data.sessionDetails.sessionAttributes;
            slotToElicit = data.sessionDetails.slotToElicit;
          }

          if (data.sessionState) {
            this.sessionAttrib = data.sessionState.sessionAttributes;
            slotToElicit = data.sessionState.dialogAction.slotToElicit;
          }
          if (data.userInfo) {
            this.userDetails = data.userInfo;
            localStorage.setItem('phone', data.sessionState.intent.slots.phone.value.originalValue);
            localStorage.setItem('email', data.userInfo.email);
            localStorage.setItem('name', data.userInfo.name);

          } else {
            data.userInfo = this.userDetails;
          }
          this.lastMsg.showInput = true;
          this.lastMsg.timestamp = new Date().getTime()
          this.formValue = "";
          if (divId) {
            const el = document.getElementById(divId);
            const all = el ? el.getElementsByTagName("button") : null;
            if (all) {
              for (let i = 0; i < all.length; i++) {
                all[i].className += " btn-disabled";
                all[i].disabled = true;
              }
            }
          }
        });
    }
    this.formValue = "";
  }

  addEmoji(event) {
    if (!this.formValue) {
      this.formValue = "";
    }
    this.formValue = this.formValue + event.emoji.native;
    $("#text").val($("#text").val() + event.emoji.native);
    this.showEmoj = !this.showEmoj;
  }

  switchEmoj() {
    this.showEmoj = !this.showEmoj;
    if (this.showEmoj == true) {
      $(".float-icon").css("z-index", "99");
    } else {
      console.log("open it ");
      $(".float-icon").css("z-index", "0");
    }
  }

  closeMessage() {
    $(".welcome-message-toggle").hide();
  }

  clearMessages(): void {
    this.chat.stopAgent=true;
    this.chat.clearmsg();
    console.log("lastMsg -> ",this.lastMsg)
    if(this.chat.checkAgent() || localStorage.getItem('agentSwitch') == 'true'){
      this.sendMessage("User left the conversation", null, null, null, false);
      this.chat.agentSwitch=false;
      console.log('Clearing messages...');
      this.chat.conversation.next([]);
      this.starRatingModuleTriggerCount = 0;
      const greetingMessage = this.chatbot_lang === 'ar' ? 'مرحبا' : 'Hello';
      console.log('Sending greeting message:', greetingMessage);
      this.chat.conversation.next([]);
      setTimeout(() => {
        this.chat.conversation.next([]);
      }, 1000);
      setTimeout(() => {
        this.chat.conversation.next([]);
        this.sendMessage(greetingMessage, null, null, null, false);
      }, 4000);
    }else{
        this.chat.stopAgent=true;
        this.chat.conversation.next([]);
        this.starRatingModuleTriggerCount = 0;
        const greetingMessage = this.chatbot_lang === 'ar' ? 'مرحبا' : 'Hello';
        console.log('Sending greeting message:', greetingMessage);
          this.sendMessage(greetingMessage, null, null, null, false);
   }

  }


  importFile(files: File[]) {
    // this.uploading = true;
    const file = files[0];
    console.log("file type -> ",file.type.indexOf('application/pdf'))
    const reader = new FileReader();
    if (
        file.type.indexOf('image/') !== -1 ||
        file.type.indexOf('application/pdf') !== -1 ||
        file.type.indexOf('application/msword') !== -1 ||
        file.type.indexOf('audio/mpeg') !== -1 ||
        file.type.indexOf('text/plain') !== -1 ||
        file.type.indexOf('video/mp4') !== -1
    ) {
      reader.onload = () => {
        const obj = {
          fileName: file.name.replace(/\s/g, ''),
          fileSize: file.size,
          fileType: file.type,
          mode: 'Put'
        };
        this.chat
            .getSignedUrlToUpload(obj)
            .subscribe((response: any) => {
              if (response && response.url) {
                this.chat
                    .uploadFile(`${response.url}`, file)
                    .subscribe((res: any) => {
                    console.log("response -> ",res)
                      if (!res) {
                        if (file.type.indexOf("image/") !== -1) {
                          this.sendMessage(`attachment:${response.fileName}:${file.type}`, null, null, reader.result);
                        } else {
                          console.log("check bdf -> ",res)
                          const obj1 = {
                            fileName: obj.fileName,
                            fileType: obj.fileType,
                            mode: "Get",
                          };
                          this.chat.getSignedUrlToUpload(obj1).subscribe((res) => {
                            if (res && res.url) {
                              this.sendMessage(`attachment:${response.fileName}:${file.type}`, null, null, null, this.chat.replaceLinksInMessage(res.url, obj.fileName));
                            }
                          })
                        }
                      }
                    });
              } 
            });
      };
      reader.readAsDataURL(file);
    } 
  }
  triggerRatingModuleFrom(component) {
    if (component == 'header-component' && this.starRatingModuleTriggerCount > 0) {
      // if(){
      this.clearMessages()
      this.toggleService.toggleChatbot(2);
      // }
    } else {
      this.starRatingModuleTriggerCount = + this.starRatingModuleTriggerCount + 1
    }
  }

  getData(data) {
    console.log(data);  
  }
  sessionIdFn() {
    if (localStorage.getItem("sessionId")) {
      this.sessionId = localStorage.getItem("sessionId");
    } else {
      this.sessionId = Math.floor(
        new Date().valueOf() * Math.random()
      ).toString();
      localStorage.setItem("sessionId", this.sessionId);
    }
  }
}
