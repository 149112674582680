import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-rating',
  templateUrl: './button-rating.component.html',
  styleUrls: ['./button-rating.component.css']
})
export class ButtonRatingComponent implements OnInit {
  buttons = [
    { title: 'Amazing! Im So statisfied', isClickable: false, rate: '5' },
    { title: 'I was very satisfied', isClickable: false, rate: '4'},
    { title: 'It was a good experience', isClickable: false, rate: '3'},
    { title: 'Found what Im looking for', isClickable: false, rate: '2'},
    { title: 'Not satisfied at all', isClickable: false, rate: '1'}
  ];
  constructor() { }

  ngOnInit() {
  }
  setEduction(index) {
    this.buttons[index].isClickable = true;
  }
}
