import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabledata',
  templateUrl: './tabledata.component.html',
  styleUrls: ['./tabledata.component.css']
})


export class TabledataComponent implements OnInit {
  
  constructor() { }

  tableHeader:Array<any>;
  tableData:Array<any>
  @Input() table;

  ngOnInit() {
    if (typeof this.table === 'string') {
      this.table = JSON.parse(this.table);
    }
    this.tableHeader = this.table.table[0];
    this.tableData = this.table.table.slice(1,)
  }
  
}
