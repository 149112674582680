import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class ScrollService {

  constructor(private router: Router) { }


  scroll(target: HTMLElement, direction, increment) {
    if (direction === 'right') {
      target.scrollLeft += + increment;
    } else {
      target.scrollLeft -= increment;
    }
  }
}
