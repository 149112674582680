import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emjo-rating',
  templateUrl: './emjo-rating.component.html',
  styleUrls: ['./emjo-rating.component.css']
})
export class EmjoRatingComponent implements OnInit {
  buttons = [
    { URL: 'assets/imgs/excellentemjo.png', isClickable: false , disable: false},
    { URL: 'assets/imgs/happyemjo.png', isClickable: false, disable: false},
    { URL: 'assets/imgs/sademjo.png', isClickable: false, disable: false},
    { URL: 'assets/imgs/angryemjo.png', isClickable: false, disable: false},
    { URL: 'assets/imgs/cryemjo.png', isClickable: false, disable: false},
  ];
  constructor() { }

  ngOnInit() {
  }
  setEduction(index) {
    this.buttons[index].isClickable = true;
    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].disable = true;
    }
  }
}
